import React, { FC } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import theme from "../../../gatsby-theme-material-ui-top-layout/theme";
import { Link } from "gatsby-theme-material-ui";


type ProductMiniSummaryProps = {
  product: any
}

const maxName = 70
const maxOpinion = 200

export const ProductMiniSummary:FC<ProductMiniSummaryProps> = ({ product }) => {
  const clippedName = product.productName.length <= maxName ? product.productName : product.productName.slice(0, maxName - 3) + "..."
  const opinion = product.opinionJson?.result_text ? product.opinionJson.result_text : "No opinion available. See more for pros and cons."
  const clippedOpinion = opinion.length <= maxOpinion ? opinion : opinion?.slice(0, maxOpinion - 3) + "..."
  const productHref = "/product/" + product.slug.toLowerCase()
  const updatedTime = new Date(product.updatedAt).toDateString()

  return (
    <Grid item xs={12} md={5.5} borderBottom={1} margin={2} padding={0} borderColor={theme.palette.primary.main}>
      <Typography variant="h6" color={theme.palette.primary.dark}>
        <Link href={productHref}>{clippedName}</Link>
      </Typography>
      
      <Typography variant="body2" component="span">
        {clippedOpinion} <Link href={productHref}>more</Link>
      </Typography>

      <Stack direction="row" justifyContent="flex-end">
        <Typography variant="caption" component="div" color={theme.palette.grey[500]}>
          Updated: {updatedTime}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default ProductMiniSummary;
