import React, { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

import StandardFooter from "../components/reviewskew/standardpage/StandardFooter"
import StandardHeader from "../components/reviewskew/standardpage/StandardHeader";

import { graphql, PageProps } from "gatsby";
import ProductMiniSummary from "../components/reviewskew/product/ProductMiniSummary";
import RecentReviewsButton from "../components/reviewskew/landing/RecentReviewsButton";
import LandingHero from "../components/reviewskew/landing/LandingHero";
import Overview from "../components/reviewskew/landing/Overview";
import FavortieBrands from "../components/reviewskew/landing/FavoriteBrands";


const HomePage:FC<PageProps<Queries.RecentProductReviewsQuery>> = ({ data }) => {

  const products = data.allContentfulProductReviewSummary.nodes;

  return (
    <Box>
      <Helmet title="Review Skew - AI Product Review Summaries" />

      <StandardHeader />
      
      <LandingHero>
        <Overview buttons={false} />
      </LandingHero>

      <Box padding={2}>
        <Typography variant="h3">Recent Product Review Summaries</Typography>

        <Grid container justifyContent="space-around">
          {products.map( (product: any) => {
            return <ProductMiniSummary product={product} />
          })}
        </Grid>

        <Box textAlign="center">
          <RecentReviewsButton more={true} />
        </Box>
      </Box>

      <Box padding={2}>
        <FavortieBrands />
      </Box>

      <StandardFooter />
    </Box>
  );
};

export default HomePage;


export const pageQuery = graphql`
query RecentProductReviews {
  allContentfulProductReviewSummary(sort: {order: DESC, fields: [updatedAt]}, limit: 6) {
    nodes {
      productName
      slug
      opinionJson {
        result_text
      }
      updatedAt
    }
  }
  }
`;
